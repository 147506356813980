import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'
import Image from 'gatsby-image'
import { breakpoints } from '../../utils/styles'


export const svgMask = css`
  width: 100%;
  height: 100%;
  position: absolute;
`

export const StyledSVG = styled.svg`
  position: absolute;
  height: 100%;
  width: 100%;
`

export const HeroImg = styled(Image)`
  height: 100%;
  min-height: 400px;
  max-height: 700px;
`

export const HeroTitle = styled.h1`
  font-size: 2rem;
  max-width: 100%;
  position: absolute;
  z-index: 50;
  top: 0;
  text-transform: uppercase;
  color: black;
  line-height: 1;
  
  
  @media (min-width: ${breakpoints.s}px){
    font-size: 4rem;
  }
  @media (min-width: ${breakpoints.m}px){
    font-size: 5rem;
    
  }
  @media (min-width: ${breakpoints.l}px){
    font-size: 8rem;
    
  }
`

export const HeroTag = styled.div`
  display: flex;
  align-items: center;
  color: black;
  height: 60%;
  border-radius: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 90%;
  margin: 0.4rem;
  @media (min-width: ${breakpoints.s}px){
    width: 90%;
    height: 50%;
    margin: 1rem;
  }
 
  @media (min-width: ${breakpoints.m}px){
    width: 50%;
    height: 60%;
    margin:  0 2rem;
  }
  @media (min-width: ${breakpoints.l}px){
    width: 50%;
    height: 40%;
    margin: 2rem;
  }
  
  h2{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 1rem;
    font-size: 0.8rem;
    margin-bottom: 0;
    @media (min-width: ${breakpoints.s}px){
      font-size: 1.0rem;
    }
    @media (min-width: ${breakpoints.m}px){
      font-size: 1.2rem;
    }
    @media (min-width: ${breakpoints.l}px){
      font-size: 1.5rem;
    }
  }
  svg{
    align-items: center;
  }
  
`


export const Wrapper = styled.div`
  background: transparent;
  margin-bottom: 1.45rem;
`
const Gradient = keyframes`
  0%{background-position:50% 0%}
  50%{background-position:50% 100%}
  100%{background-position:50% 0%}
`

export const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: ${breakpoints.xl}px;

`

export const HeroContent = styled.div`


  height: 100%;
  overflow: hidden;
  position: relative;
`
