import React from 'react'
import SEO from '~/components/seo'
import Hero from '~/components/Hero'
import ProductGrid from '~/components/ProductGrid'


const IndexPage = () => (
  <>
    <SEO title="Lucie Conoley | The Mips" description="The Most important places, drawn from the mind of Lucie Conoley" keywords={[`Mips`, `maps`, `prints`]} />

    <Hero />

    <ProductGrid tag={'London'}/>

  </>
)

export default IndexPage
