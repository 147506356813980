
import styled from '@emotion/styled'
import Image from 'gatsby-image'
import { breakpoints, colours } from '../../utils/styles'
import { Link } from 'gatsby'


export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.maxColumns ? props.maxColumns : "3"}, 1fr);
  gap: 2.5rem;

  @media (max-width: ${breakpoints.s}px){
    grid-template-columns: repeat(1, 1fr);
  }
`
export const Info = styled(Link)`
  margin-top: -100px;
  margin-left: -0.5rem;
  text-decoration: none;
  z-index: 1;
  background: white;
  padding: 0.2rem;
  text-align: center;
  border: 4px solid ${colours.blue};
  box-shadow: 8px 8px ${colours.blue};
  font-weight: 800;
  & span{
    font-weight: 800;
  }
`

export const ProductLink = styled(Link)`
  z-index: 1;
 height: 100%;
 color: ${colours.contrast};
`

export const ProductImg = styled(Image)`
  height: 100%;
`

export const Product = styled.div`
  display: flex;
  min-height: 100%;
  background: #ffffef;
  flex-direction: column;
`

export const Title = styled.span`
  font-weight: 300;
  font-size: 1.2rem;
  text-align: center;
  color: ${colours.blue};
`

export const PriceTag = styled.span`
  font-weight: 300;
  font-size: 1rem;
  text-align: center;
  margin-top: 15px;
  color: ${colours.pink};
  :before {
    content: '- '
  }
`