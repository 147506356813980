import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import StoreContext from '~/context/StoreContext'
import {
  Grid,
  Product,
  Title,
  PriceTag,
  Info,
  ProductLink,
  ProductImg
} from './styles'
import { Container } from '~/utils/styles'
import { isEmpty } from 'lodash'


const ProductGrid = ({maxColumns ,tag}, {children}) => {
  const { store: {checkout} } = useContext(StoreContext)
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(
          sort: {
            fields: [createdAt]
            order: DESC
          }
        ) {
          edges {
            node {
              id
              title
              handle
              createdAt
              tags
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600, maxHeight: 600)  {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              variants {
                price
              }
            }
          }
        }
      }
    `
  )

  const getPrice = price => Intl.NumberFormat(undefined, {
    currency: checkout.currencyCode ? checkout.currencyCode : 'EUR',
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(parseFloat(price ? price : 0))

  return (
    <Container>
    {children}
    <Grid maxColumns={maxColumns}>
      
      {allShopifyProduct.edges
        ? allShopifyProduct.edges.map(({ node: { id, tags, handle, title, images: [firstImage], variants: [firstVariant] } }) => (
          tags.includes(tag) && (
          <Product key={id} >
            <ProductLink to={`/product/${handle}/`}>
              {firstImage && firstImage.localFile && firstImage.localFile.childImageSharp && 
                (<ProductImg
                  fluid={firstImage.localFile.childImageSharp.fluid}
                  alt={handle}
                />)}
            </ProductLink>
            
            <Info  to={`/product/${handle}/`}>
            <Title>{title}</Title>
            {firstVariant.price > 0 && (
            <PriceTag>{getPrice(firstVariant.price)}</PriceTag>
            )}
            </Info>
          

          </Product>)
        ))
        : <p>No Products found!</p>}
    </Grid>
    </Container>
  )
}

export default ProductGrid
