import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { Wrapper, HeroContent, HeroImg, HeroTag, HeroTitle, StyledSVG } from './styles'
import { InnerContainer } from '~/utils/styles'

const Hero = () => (
  <StaticQuery
    query={graphql`
      query {
        heroImage: file(relativePath: { eq: "south-london-pink.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <InnerContainer>
          <HeroContent>
            <HeroImg
              fluid={data.heroImage.childImageSharp.fluid}
              alt="Lucie Conoley"
            />

			<HeroTitle>
				Lucie Conoley
			</HeroTitle>
			
			<HeroTag>

			<StyledSVG width="100%" height="100%" viewBox="0 18 500 221" preserveAspectRatio="none" >
				<path d="m13.91 61.641c32.107-54.305 443.728-51.682 463.215-30.006 23.557 26.202 38.734 165.267-14.497 171.444-41.131 4.772-120.603 25.464-139.691 30.014-21.647 5.159-298.374 16.387-315.076-30.014-6.81-18.92-15.575-104.864 6.049-141.438z" fill="#fff"/>
			</StyledSVG>
				<h2>
				...Maker of bespoke illustrated maps.
				Professional colourer-inner, Tea drinker, superb at cartwheels!</h2>
			</HeroTag>
          </HeroContent>
        </InnerContainer>
      </Wrapper>
    )}
  />
)

Hero.propTypes = {
  siteTitle: PropTypes.string,
}

Hero.defaultProps = {
  siteTitle: ``,
}

export default Hero
